import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import pbiLogo from "../static/image/pbi_logo.svg";

const clientList = [
  {
    name: "Defence Bank",
    urlHead: "defence-bank",
    description: "",
    urlLogoImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3y6tcgtndQiTcfBGgvevJ0eJwFLoxtzZZu0lb2Z68fA&s"
  },
  {
    name: "EACH",
    urlHead: "each",
    description: "",
    urlLogoImage: "https://www.sureaod.org.au/wp-content/uploads/2016/07/Each-Logo-05.png"
  },
  {
    name: "Intereach Aged Care",
    urlHead: "intereach-aged-care",
    description: "",
    urlLogoImage: "https://www.intereach.com.au/wp-content/uploads/2018/04/IntereachLogo_Black.jpg"
  },
  {
    name: "Intereach Family Day Care",
    urlHead: "intereach-family-day-care",
    description: "",
    urlLogoImage: "https://www.intereach.com.au/wp-content/uploads/2018/04/IntereachLogo_Black.jpg"
  },
  {
    name: "PSA",
    urlHead: "psa",
    description: "",
    urlLogoImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUHhhthzOSH9siKnlPCDOtLycFX6ndrK7_OsVQwBlFPQ&s"
  },
  {
    name: "WCS",
    urlHead: "wcs",
    description: "",
    urlLogoImage: "https://images.squarespace-cdn.com/content/v1/5a0981cb49fc2b1d7ba813ab/55654b03-6c04-4e07-95f2-42e373c7fe4c/WCS+Vector.png"
  },
];

const exClientList = [
  {
    name: "ECA",
    urlHead: "eca",
    description: "",
    urlLogoImage: "https://pbs.twimg.com/profile_images/458788067327746048/M-fKDs2z_400x400.jpeg"
  },
  {
    name: "Federation",
    urlHead: "federation",
    description: "",
    urlLogoImage: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVUaEaViGhUCiKqPBhkCovc7aM4WDne1WFkbIjXmsdQg&s"
  },
  {
    name: "Flinders",
    urlHead: "flinders",
    description: "",
    urlLogoImage: "https://www.courseseeker.edu.au/assets/images/Flinders_University_Logo_Horizontal_RGB_Master.png"
  },
  {
    name: "NCI",
    urlHead: "nci",
    description: "",
    urlLogoImage: "https://www.dewr.gov.au/sites/default/files/images/2020-08/NCI_Stacked_Block_Red.svg"
  },
  {
    name: "SK Road Safety Public Data",
    urlHead: "sk-road-safety-public-data",
    description: "",
    urlLogoImage: "https://media.licdn.com/dms/image/C560BAQFprgEfRLfYPg/company-logo_200_200/0/1630646356042/the_audit_office_of_new_south_wales_logo?e=2147483647&v=beta&t=GYqXHOWHa4otIK1B2fPbSlavsfnHpRV7wOi7QRNErUs"
  },
]

const ClientList = () => {
  const openReport = (urlHead: string) =>
    window.open(`https://${urlHead}.commpact.com.au`, "_blank");
  return (
    <Grid container width="100%" my={1}>
      <Grid item xs={11}>
        <Typography paddingLeft={1} variant="subtitle1">
          Client Reports
        </Typography>
        <List sx={{ width: "100%", paddingY: 0 }}>
          {clientList
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((client) => (
              <>
                <ListItem
                  key={client.name}
                  sx={{ padding: 0, borderTop: "1px solid lightgrey" }}
                >
                  <ListItemButton onClick={() => openReport(client.urlHead)}>
                    <ListItemIcon>
                      <Box
                        component={"img"}
                        src={pbiLogo}
                        alt="Logo"
                        maxHeight={"32px"}
                      />
                    </ListItemIcon>
                    <ListItemIcon>
                      <Box
                        component={"img"}
                        src={client.urlLogoImage}
                        alt="Logo"
                        maxHeight={"32px"}
                        maxWidth={"42px"}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2">{client.name}</Typography>
                      }
                      secondary={client.description}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ))}
        </List>
        <br></br>    
        <br></br>     
        <Typography paddingLeft={1} variant="subtitle1">
          Ex-Client Reports
        </Typography>
        <List sx={{ width: "100%", paddingY: 0 }}>
          {exClientList
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((client) => (
              <>
                <ListItem
                  key={client.name}
                  sx={{ padding: 0, borderTop: "1px solid lightgrey" }}
                >
                  <ListItemButton onClick={() => openReport(client.urlHead)}>
                    <ListItemIcon>
                      <Box
                        component={"img"}
                        src={pbiLogo}
                        alt="Logo"
                        maxHeight={"32px"}
                      />
                    </ListItemIcon>
                    <ListItemIcon>
                      <Box
                        component={"img"}
                        src={client.urlLogoImage}
                        alt="Client Logo"
                        maxHeight={"32px"}
                        maxWidth={"42px"}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2">{client.name}</Typography>
                      }
                      secondary={client.description}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ))}
        </List>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export default ClientList;
